import Vue from 'vue'
import App from './App.vue'

// 时间插件
import dayjs from "dayjs"
import 'dayjs/locale/zh-cn'
dayjs.locale('zh-cn')
Vue.prototype.$dayjs = dayjs;

// 接口调用
import VueResource from 'vue-resource'
Vue.use(VueResource)

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')
