<template>
  <div class="tablepageBox">
    <div class="userName">{{ dealerName }}</div>
    <div class="pageTitle">二维码引流统计</div>
    <div class="loadBox">
      <div class="loading" v-if="loading">加载中……</div>
      <div class="pickerBox">
        <month-picker-input
          :default-month="Number(dateData.defaultMonth)"
          :default-year="Number(dateData.defaultYear)"
          :selectedDateStr="dateData.selectedDateStr"
          @change="changeDate"
        ></month-picker-input>
      </div>
      <div class="tableBox">
        <!-- 表头 -->
        <div class="tableTop">
          <div class="tableTr">
            <div class="tableItem">日期</div>
            <div class="tableItem">新用户<br />注册数</div>
            <div class="tableItem">佣金</div>
            <div class="tableItem">首单<br />结算数</div>
            <div class="tableItem">佣金</div>
          </div>
        </div>
        <div class="tableContent">
          <div class="tableTr" v-for="(item, index) in list" :key="index">
            <div class="tableItem">{{ item.date }}</div>
            <div class="tableItem">{{ item.reg_count || 0 }}</div>
            <div class="tableItem">{{ item.reg_commission || 0 }}</div>
            <div class="tableItem">{{ item.pay_count || 0 }}</div>
            <div class="tableItem">{{ item.pay_commission || 0 }}</div>
          </div>
        </div>
        <div class="tableBottom">
          <div class="tableTr">
            <div class="tableItem">月度统计</div>
            <div class="tableItem">{{ countData.reg_count || 0 }}</div>
            <div class="tableItem">{{ countData.reg_commission || 0 }}</div>
            <div class="tableItem">{{ countData.pay_count || 0 }}</div>
            <div class="tableItem">{{ countData.pay_commission || 0 }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="erweimaBox">
      <div class="erweimaBoxLeft">
        <div class="erweimaBoxLeftBlock" @click="creatErweimaCode">
          点击此处下载二维码
        </div>
      </div>
      <div class="erweimaBoxRight">
        <img
          alt="logo"
          src="../assets/logo.png"
          style="width: auto; height: 38px"
        />
      </div>
    </div>
    <!-- 生成的二维码弹窗 -->
    <div class="modelDiag" v-show="modelDiagFlag">
      <div class="modelDiagBox">
        <div class="erweimacode" ref="erweimacodeRef"></div>
        <div class="closeDiagBtn" @click="modelDiagFlag = false">关闭</div>
      </div>
    </div>
  </div>
</template>

<script>
import MonthPickerInput from "./MonthPickerInput";
import QRCode from "qrcodejs2";

export default {
  name: "TablePage",
  components: { MonthPickerInput },
  data() {
    const _this = this;
    return {
      dateData: {
        defaultMonth: _this.$dayjs().format("MM"),
        defaultYear: _this.$dayjs().format("YYYY"),
        selectedDateStr: _this.$dayjs().format("YYYY/MM"),
      },
      dealerID: "",
      dealerName: "",
      dealerQR: "",
      loading: false,
      list: [],
      countData: {},
      modelDiagFlag: false,
    };
  },
  mounted() {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    this.dealerID = params.did;
    this.getList();
  },
  methods: {
    changeDate(date) {
      const selectedDateStr = this.$dayjs(
        `${date.selectedYear}/${date.monthIndex}`,
        "YYYY/M"
      ).isValid()
        ? this.$dayjs(
            `${date.selectedYear}/${date.monthIndex}`,
            "YYYY/M"
          ).format("YYYY/MM")
        : "";
      this.dateData = { ...this.dateData, selectedDateStr: selectedDateStr };
      this.getList();
    },
    getList() {
      if (!this.dealerID) {
        return;
      }
      this.loading = true;
      this.$http
        .get("https://api.cleanxwork.cn/dealers/stats", {
          params: {
            id: this.dealerID,
            month: this.dateData.selectedDateStr.replaceAll("/", ""),
          },
        })
        .then(
          function (res) {
            // 响应成功回调
            if (res.body.code == 0) {
              this.dealerName = res.body.data.name;
              this.dealerQR = res.body.data.qr_url;
              this.list = res.body.data.stats;
              this.loading = false;
              this.countData = {
                reg_count: this.list
                  .map((n) => n.reg_count)
                  .reduce((sum, m) => sum + Number(m), 0),
                reg_commission: this.list
                  .map((n) => n.reg_commission)
                  .reduce((sum, m) => sum + Number(m), 0),
                pay_count: this.list
                  .map((n) => n.pay_count)
                  .reduce((sum, m) => sum + Number(m), 0),
                pay_commission: this.list
                  .map((n) => n.pay_commission)
                  .reduce((sum, m) => sum + Number(m), 0),
              };
            }
          },
          function (res) {
            console.log(res);
            // 响应错误回调
          }
        );
      // setTimeout(() => {}, 1000);
    },
    creatErweimaCode() {
      this.$refs.erweimacodeRef.innerHTML = "";
      const erweimacode = new QRCode(this.$refs.erweimacodeRef, {
        text: this.dealerQR, // 需要转换为二维码的内容
        width: 200,
        height: 200,
        colorDark: "#000000",
        colorLight: "#ffffff",
        correctLevel: QRCode.CorrectLevel.H,
      });
      this.modelDiagFlag = true;
      console.log("erweimacode", erweimacode);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.tablepageBox {
  padding: 15px;
  padding-top: 25px;
  padding-bottom: 0px;
}
.tablepageBox .userName,
.tablepageBox .pageTitle {
  text-align: center;
  font-size: 24px;
  color: #000;
}
.tablepageBox .pageTitle {
  font-size: 20px;
  color: #737373;
}
.pickerBox {
  padding-top: 20px;
}
/* 表格 S */
.tableBox {
  margin-top: 20px;
  margin-bottom: 10px;
  height: calc(100vh - 230px - 30px);
}
.tableContent {
  max-height: calc(100vh - 230px - 30px - 140px - 15px);
  border-bottom: 1px solid #cbcbcb;
  overflow-y: scroll;
}
.tableContent .tableTr:last-child .tableItem {
  border-bottom: none;
}
.tableTr {
  overflow: hidden;
  display: flex;
  align-items: stretch;
  border-right: 1px solid #cbcbcb;
}
.tableTr .tableItem {
  width: 20%;
  height: 40px;
  border: 1px solid #cbcbcb;
  border-top: none;
  border-right: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #333;
  background: #fff;
}

.tableTop,
.tableBottom {
  overflow-y: scroll;
}
.tableTop .tableItem {
  color: #fff;
  background: #2860f5;
  border-bottom: none;
  border-top: 1px solid #cbcbcb;
  font-weight: 600;
}
.tableBottom .tableItem {
  color: #2860f5;
  font-weight: 600;
}
/* 表格 E */
.erweimaBox {
  padding: 10px 0px;
  background: #fff;
  border-radius: 20px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
  overflow: hidden;
  text-align: center;
  display: flex;
  align-items: center;
}
.erweimaBox .erweimaBoxLeft {
  width: 68%;
  padding: 5px 0;
  border-right: 1px solid #eaeaea;
}
.erweimaBox .erweimaBoxLeft .erweimaBoxLeftBlock {
  background-image: url("../assets/click.png");
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 28px 28px;
  padding-left: 40px;
  display: inline-block;
  color: #000;
  cursor: pointer;
}
.erweimaBox .erweimaBoxLeft .erweimaBoxLeftBlock:hover {
  text-decoration-line: underline;
}
.erweimaBox .erweimaBoxRight {
  width: 32%;
}
.loadBox {
  position: relative;
}
.loadBox .loading {
  position: absolute;
  z-index: 9999;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #2860f5;
  font-size: 12px;
}
.modelDiag {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(255, 255, 255, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999;
}
.modelDiag .modelDiagBox {
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0px 6px rgba(0, 0, 0, 0.1);
}
.modelDiag .modelDiagBox .erweimacode {
  width: 200px;
  height: 200px;
}
.modelDiag .modelDiagBox .closeDiagBtn {
  width: 140px;
  background-color: #fff;
  color: #2860f5;
  border-color: #1e1e1e;
  border-radius: 25px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
  height: 2.5rem;
  line-height: 2.5rem;
  font-size: 1.1rem;
  margin: 0 auto;
  margin-top: 20px;
  cursor: pointer;
}
</style>
